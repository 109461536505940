import {defineStore, storeToRefs} from "pinia";
import {useShopStore} from "~/stores/shop";
import type {Ref} from "vue";
import type {ProductTypes, ShopProduct} from "~/_types/shop/product";
import type {Categories} from "~/_types/shop/categories";
import type {ApiResponse, GenericErrorApiResponse, ValidationErrorApiResponse} from "~/_types/api/response";


export const useProductStore = defineStore("product", () => {
            const shopStore = useShopStore();
            const {shopToken} = storeToRefs(shopStore);
            const route = useRouter().currentRoute;

            const config = useRuntimeConfig();


            const prodDialog = ref(false);

            const searchQuery = ref<string>('');

            const filteredCategories: Ref<ProductTypes[]> = ref([]);
            /**
             * Current Product ID based on route.
             */
            const productId: Ref<string> = computed(() => {
                const id = route.value.params?.["product_id"];
                return Array.isArray(id) ? id[0] : id;
            });

            const productData: Ref<ProductTypes[]> = ref([]);
            const productError: Ref<ValidationErrorApiResponse | GenericErrorApiResponse | null> = ref(null);

            async function parseFetch() {
                const res: ApiResponse<any> = await $fetch("/api/product", {
                        params: {shopToken: shopToken.value}
                    }
                );
                if (res.type === 'success') {
                    productData.value = res.data;
                } else {
                    console.log('error');
                    productError.value = res.error;
                }
            }

            const categories: Ref<Categories> = computed(() => {
                let cat_arr: Categories = [];
                if (productData.value == null) {
                    return [];
                }
                productData.value.forEach((cat: any) => {
                    cat_arr.push({
                        id: cat.id,
                        name: cat.name,
                        count: cat.products.length,
                    });
                });
                return cat_arr;
            });


            // Search product using id
            const searchProductId = (subProductId: string): ShopProduct | null => {
                for (const item of productData.value ?? []) {
                    for (const product of item.products) {
                        if (product.id === subProductId) {
                            return product;
                        }
                    }
                }
                // Return null if sub-product ID is not found
                return null;
            };

            function filterCategoriesByProductName(categories: ProductTypes[], productName: string): ProductTypes[] {
                return (categories ?? []).map(category => ({
                    ...category,
                    products: category.products.filter(product => {
                            return product.name.toLowerCase().includes(productName.toLowerCase())
                        }
                    )
                }));
            }

            async function filterProducts() {
                if (!searchQuery.value || searchQuery.value === "") {
                    filteredCategories.value = productData.value;
                } else {
                    const search = searchQuery.value.toLowerCase();
                    filteredCategories.value = filterCategoriesByProductName(productData.value, search);
                }
            }

            return {
                productId,
                prodDialog,
                productData,
                productError,
                categories,
                searchQuery,
                filteredCategories,
                filterProducts,
                searchProductId,
                parseFetch,
            };
        }
    )
;
