import type {Ref} from "vue";
import type {ApiResponse} from "~/_types/api/response";
import type {Shop} from "~/_types/shop/shop";

export const useShopStore = defineStore("shop", () => {
    /**
     * Current Route.
     */
    const route = useRouter().currentRoute;

    /**
     *  Ref is dialog should be opened
     */
    const qrDialog: Ref<boolean> = ref(false);

    /**
     *  Ref is organistion info should be opened
     */
    const orgInfoModal: Ref<boolean> = ref(false);
    /**
     * Get the runtime config.
     */
    const config = useRuntimeConfig();

    /**
     * API Route for shop info GET.
     */
    const shopApiRoute = computed(() => {
        return `${config.public.apiBaseUrl}v2/table-pos/${route.value.params["shop_id"]}`;
    });


    /**
     * Automatically refresh shop when the route (shopToken) changes.
     */
    watch(
        shopApiRoute,
        async () => {
            await refresh();
        },
        {
            immediate: false,
        },
    );


    // /**
    //  * The useFetch composable used for the fetching of the shop data.
    //  */
    // const fetchShop = useFetch<Shop>(() => shopApiRoute.value, {
    //     headers: {
    //         'Authorization': `Bearer ${config.public.bearerToken}`,
    //     },
    //     immediate: true,
    //     watch: false,
    //     retry: 0,
    //     transform: makeParser(shopSchema)
    // });
    //
    // const {data: shopData, pending: shopPending, error: shopError, status: shopStatus} = fetchShop;

    /**
     * Get the shop token.
     */
    const shopToken = computed(() => {
        const id = route.value.params?.["shop_id"];
        return Array.isArray(id) ? id[0] : id;
    });


    const shopData: Ref<Shop | null> = ref(null);
    const shopError: Ref<any> = ref(null);

    // TODO do this differently??
    fetchShopData();

    async function fetchShopData() {
        const shopResponse: ApiResponse<any> = await $fetch("/api/shop", {
            params: {
                shopToken: shopToken.value
            }
        });

        if (shopResponse.type === 'success') {
            shopData.value = shopResponse.data;
        } else {
            shopError.value = shopResponse.error;
        }
    }


    /**
     * Get the useFetch.
     *
     * Usage in component with <Suspense>:
     * ```
     * await fetchData()
     * ```
     */
    async function fetchData() {
        return fetch;
    }

    /**
     * Refresh shop data.
     */
    async function refresh() {
        // await fetch.refresh();
    }


    const localstorageKey = computed(() => {
        return `shop_order_${shopToken.value}`;
    });

    return {
        shopData,
        qrDialog,
        localstorageKey,
        shopToken,
        orgInfoModal,
        fetchData,
        fetch,
        refresh,
    };
});
