import {z} from "zod";

const mappedSubProdSchema = z.object({
    id: z.string(),
});

export const mappedProdSchema = z.object({
    prod_items_id: z.string(),
    id: z.string(),
    count: z.number(),
    subProducts: z.array(mappedSubProdSchema),
});

export const mappedProdArraySchema = z.array(mappedProdSchema);
export const mappedProdArraySchemaLocalStorage = z.object({
    mappedProducts: z.array(mappedProdSchema)
})

export type mappedProdArrayType = z.infer<typeof mappedProdArraySchema>;
export type mappedProdSchemaType = z.infer<typeof mappedProdSchema>;
