import {skipHydrate, storeToRefs} from "pinia";
import type {ComputedRef, Ref} from "vue";
import {useShopStore} from "~/stores/shop";
import type {MappedProduct, storedOrderSchema} from "~/_types/store/shop/order";
import type {ApiResponse, ErrorApiResponse} from "~/_types/api/response";
import {useProductStore} from "~/stores/product";
import type {paymentMethods} from "~/_types/shop/payment_methods";

export const useOrderStore = defineStore("order", () => {
            const route = useRouter().currentRoute;
            const localePath = useLocalePath();

            const productsStore = useProductStore();
            const {productData} = storeToRefs(productsStore);
            const {searchProductId} = productsStore;

            const shopStore = useShopStore();
            const {shopToken, localstorageKey} = storeToRefs(shopStore);

            const itemStore = useItemSelectionStore();
            const {selectedItemIds} = storeToRefs(itemStore);
            const {searchSubProductId} = itemStore;

            const placingOrder: Ref<boolean> = ref(false);
            const placingOrderError: Ref<ErrorApiResponse | false> = ref(false);
            const opened = ref(false);

            const tableCheckModal = ref(false);

            /**
             * Order object, defaults to a stored order in session storage.
             */
            const order: Ref<storedOrderSchema> = ref(makeEmptyOrder());

            /**
             * Create empty order object
             */
            function makeEmptyOrder() {
                return {
                    order_id: null,
                    table_number: null,
                    customer_email: '',
                    payment_method: 10,
                    payment_method_option: null,
                    reserved_items: null,
                    comment: ''
                };
            }

            const mappedProductOrder: Ref<MappedProduct[]> = ref([]);

            async function mapProducts(newVal: any) {
                // First product added
                if (newVal.length > 0 && !order.value?.order_id) {
                    // fetch table number
                    const cookieTable = useCookie("paytree_table_number");

                    if (!cookieTable.value) {
                        throw createError({
                            statusCode: 500,
                            statusMessage: 'Table number not found'
                        })
                    }

                    // fetch fresh order id
                    const response: ApiResponse<any> = await $fetch('/api/order/id', {
                        params: {
                            shopToken: shopToken.value,
                            tableNumber: cookieTable.value
                        }
                    });

                    // get table number
                    if (response.type !== "success") {
                        throw createError({
                            statusCode: 500,
                            statusMessage: 'Could not fetch order id'
                        })
                    } else {
                        if (order.value && cookieTable.value) {
                            order.value.order_id = response.data.reference;
                            order.value.table_number = cookieTable.value;
                        }
                    }
                } else {
                }

                mappedProductOrder.value = [];
                newVal.forEach((mappedProduct: any) => {
                    if (!productData.value) {
                        return null;
                    }

                    // all products from productData
                    function flattenProducts(products: MappedProduct[]): any {
                        console.log(products);
                        let seenIds = new Set<string>;
                        return products.flatMap(product => {
                            if (seenIds.has(product.id)) {
                                return [];
                            }
                            seenIds.add(product.id);
                            return [product, ...flattenProducts(product.subProducts)];
                        });
                    }

                    const allProducts = productData.value.flatMap(obj => flattenProducts(obj.products));

                    // find product from productData by mapped product id
                    const prod = allProducts.find((product: any) => product.id === mappedProduct.id);

                    if (prod && mappedProduct) {

                        mappedProductOrder.value.push({
                            id: prod.id,
                            prod_items_id: mappedProduct.prod_items_id,
                            name: prod.name,
                            price: prod.price,
                            count: mappedProduct.count,
                            image: prod.imageUrl ?? null,
                            subProducts: mappedProduct.subProducts,
                        });
                    }
                });
            }

            const selectedPaymentMethod: Ref<paymentMethods | null> = ref(null);

            function changeSelectedPaymentMethod(method: any) {
                selectedPaymentMethod.value = method;
            }

            /**
             * Get price  total of current order
             */
            const orderTotal = computed(() => {
                let total = 0;
                mappedProductOrder.value.forEach(item => {
                    let product = searchProductId(item.id);
                    let subItemPrice = 0;
                    if (!product) {
                        return;
                    }
                    if (item.subProducts !== undefined && item.subProducts) {
                        item.subProducts.forEach((sub: any) => {
                            let subProd = searchSubProductId(sub.id);
                            subItemPrice += Number.parseFloat(subProd.price);
                        });
                    }
                    total += ((Number.parseFloat(product.price) + (subItemPrice ?? 0)) * item.count);
                });

                return total
            });


            /**
             *  total count of products in the order
             */
            const orderProductCount: ComputedRef<number> = computed(() => {
                return mappedProductOrder.value.reduce((total, prod) => total + prod.count, 0);
            });

            /**
             * Navigate to payment page
             */
            function toPayment() {
                if (!order.value.order_id) {
                    return;
                }

                $fetch('/api/order/add', {
                    params: {
                        shopToken: shopToken.value,
                        orderReference: order.value.order_id,
                        products: mappedProductOrder.value
                    }
                });

                return localePath(
                    `/shop/${route.value.params["shop_id"]}/order/`,
                );
            }

            /**
             * Send customer to payment page
             * Await response
             * Navigate to order confirm
             */

            async function placeOrder(orderId: string = '', retry: boolean = false) {

                if (order.value.order_id) {
                    order.value.reserved_items = mappedProductOrder.value;
                }

                if (!shopToken.value) {
                    throw createError({
                        statusCode: 500,
                        statusMessage: 'Shop token not found'
                    })
                }

                if (!order.value.order_id && !orderId) {
                    throw createError({
                        statusCode: 500,
                        statusMessage: 'Order ID not found'
                    })
                }

                placingOrder.value = true;

                // Dont try to add products on retry
                if (!retry) {
                    const pto_response = await $fetch('/api/order/add', {
                        method: 'POST',
                        body: {
                            shopToken: shopToken.value,
                            orderReference: order.value.order_id,
                            products: mappedProductOrder.value,
                        }
                    });

                    if (pto_response.type === "error") {
                        placingOrderError.value = pto_response;
                        placingOrder.value = false;
                        return false;
                    }
                }

                const response: ApiResponse<any> = await $fetch("/api/order/place", {
                        method: 'POST',
                        params: {
                            cashRegisterCode: shopToken.value,
                            orderReference: order.value.order_id ?? orderId,
                            returnUrl: useRuntimeConfig().public.returnUrl,
                            tableNumber: order.value.table_number
                        },
                        body: {
                            comment: order.value.comment,
                            customerEmail: order.value.customer_email
                        }
                    }
                );

                if (response.type === "error") {
                    placingOrderError.value = response;
                    placingOrder.value = false;
                    return false;
                }

                // Empty localStorageKey with all saved orders
                useSessionStorage<{
                    mappedProducts: any | [];
                }>(localstorageKey.value, {mappedProducts: []}).value.mappedProducts = [];

                // set cookie Paytree has placed order to '1'
                useCookie('paytree_hpo').value = "1";

                navigateTo(response.data.paymentUrl, {
                        external: true
                    }
                );

                placingOrderError.value = false;
                placingOrder.value = false;

            }

            /**
             * Properties in a validation response that should be ignored in alerts.
             */
            const placeOrderIgnoreValidationErrors = [
                "payment_method",
                "payment_method_option",
                "pii.phone_number",
                "pii.first_name",
                "pii.last_name",
                "pii.email",
                "pii.street",
                "pii.street_number",
                "pii.street_number_extension",
                "pii.zip_code",
                "pii.city",
                "pii.country",
                "pii.gender",
            ];

            /**
             * Delete stored order.
             */
            function deleteOrder() {
                order.value = makeEmptyOrder();
            }

            /**
             * Navigate to product selection page
             */
            const toSelection = (): string => {
                return localePath(
                    `/shop/${route.value.params["shop_id"]}`,
                );
            }

            const qrCheck = () => {
                const cookie = useCookie('paytree_table_number');
                cookie.value = null;
                navigateTo('/shop/' + shopToken.value)
            }

            return {
                order: skipHydrate(order),
                placingOrder,
                placingOrderError,
                placeOrderIgnoreValidationErrors,
                orderTotal,
                mappedProductOrder,
                opened,
                selectedPaymentMethod,
                orderProductCount,
                tableCheckModal,
                mapProducts,
                deleteOrder,
                toSelection,
                placeOrder,
                toPayment,
                qrCheck,
            };
        }
    )
;

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useOrderStore, import.meta.hot))
}


