import type {ThemeDefinition} from "vuetify";
import type {BrandingDefinition, BrandingPublicRuntimeConfig,} from "~/_types/general/branding";

const paytreeTheme: BrandingDefinition = {
    theme: {
        dark: false,
        colors: {
            background: "#f5f5f5",
            surface: "#FFFFFF",
            primary: "#f2690d",
            secondary: "#ffab91",
            field: "#f2690d",
            negative: "#e0e0e0",
            error: "#d32f2f",
        },
    },
    publicRuntimeConfig: {
        brandName: "paytree",
        logo: "/paytree-logo-beter.png",
        brandHref: "https://paytree.nl",
        ticketsLostHref: "https://paytree.nl/tickets-kwijt",
        tcHref: "https://assets.paytree.nl/static/av_paytree.pdf",
    },
};

const albronTheme: BrandingDefinition = {
    theme: {
        dark: false,
        colors: {
            background: "#f5f5f5",
            surface: "#FFFFFF",
            primary: "#eb3754",
            secondary: "#770031",
            field: "#eb3754",
            negative: "#e0e0e0",
            error: "#d32f2f",
        },
    },
    publicRuntimeConfig: {
        brandName: "paytree",
        logo: "/paytree-logo-beter.png",
        brandHref: "https://paytree.nl",
        ticketsLostHref: "https://paytree.nl/tickets-kwijt",
        tcHref: "https://assets.paytree.nl/static/av_paytree.pdf",
    },
};


export function getBrandingDefinition(name?: string): BrandingDefinition {
    switch (name) {
        case 'albron':
            return albronTheme;
        default:
            return paytreeTheme;
    }
}

export function getDefaultTheme(): ThemeDefinition {
    const config = useRuntimeConfig();
    return getBrandingDefinition('albron').theme;
}

export function getPublicRuntimeConfigForPreset(
    preset?: string,
): BrandingPublicRuntimeConfig {
    return getBrandingDefinition(preset).publicRuntimeConfig;
}
