import {useShopStore} from "~/stores/shop";
import {useProductSelectionStore} from "~/stores/productSelection";

export default defineNuxtRouteMiddleware(async (to) => {
    // if (["/auth/login", "/auth/register", "/auth/reset"].includes(to.path))
    //     return;

    const productStore = useProductSelectionStore();
    const {checkExistingOrder} = productStore;

    const shopStore = useShopStore();
    const {qrDialog} = storeToRefs(shopStore);

    // check if table nr in url via qr
    const urlTable = Array.isArray(to.query.table) ? to.query.table[0] : to.query.table;
    const cookieTable = useCookie("paytree_table_number");
    const cookieTableHPO = useCookie("paytree_hpo");

// If both urlTable and cookieTable are undefined, show the QR dialog
    if (cookieTable.value === undefined && urlTable === undefined) {
        qrDialog.value = true;
    } else {
        checkExistingOrder();
    }

    if (urlTable !== null && typeof urlTable !== "undefined") {
        if (cookieTable.value !== urlTable || !cookieTable.value) {
            cookieTable.value = urlTable;
            cookieTableHPO.value = null;
            checkExistingOrder();
        }
    }
});
