import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as indexWljnIb2h7MMeta } from "/usr/src/nuxt-app/pages/shop/[shop_id]/index.vue?macro=true";
import { default as indexsopSEswFk7Meta } from "/usr/src/nuxt-app/pages/shop/[shop_id]/order/confirm/index.vue?macro=true";
import { default as indexFyrTVJqbx0Meta } from "/usr/src/nuxt-app/pages/shop/[shop_id]/order/index.vue?macro=true";
export default [
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___en",
    path: indexAoBMx6PnC1Meta?.path ?? "/en",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___nl",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWljnIb2h7MMeta?.name ?? "shop-shop_id___en",
    path: indexWljnIb2h7MMeta?.path ?? "/en/shop/:shop_id()",
    meta: indexWljnIb2h7MMeta || {},
    alias: indexWljnIb2h7MMeta?.alias || [],
    redirect: indexWljnIb2h7MMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/shop/[shop_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWljnIb2h7MMeta?.name ?? "shop-shop_id___nl",
    path: indexWljnIb2h7MMeta?.path ?? "/shop/:shop_id()",
    meta: indexWljnIb2h7MMeta || {},
    alias: indexWljnIb2h7MMeta?.alias || [],
    redirect: indexWljnIb2h7MMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/shop/[shop_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexsopSEswFk7Meta?.name ?? "shop-shop_id-order-confirm___en",
    path: indexsopSEswFk7Meta?.path ?? "/en/shop/:shop_id()/order/confirm",
    meta: indexsopSEswFk7Meta || {},
    alias: indexsopSEswFk7Meta?.alias || [],
    redirect: indexsopSEswFk7Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/shop/[shop_id]/order/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: indexsopSEswFk7Meta?.name ?? "shop-shop_id-order-confirm___nl",
    path: indexsopSEswFk7Meta?.path ?? "/shop/:shop_id()/order/confirm",
    meta: indexsopSEswFk7Meta || {},
    alias: indexsopSEswFk7Meta?.alias || [],
    redirect: indexsopSEswFk7Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/shop/[shop_id]/order/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: indexFyrTVJqbx0Meta?.name ?? "shop-shop_id-order___en",
    path: indexFyrTVJqbx0Meta?.path ?? "/en/shop/:shop_id()/order",
    meta: indexFyrTVJqbx0Meta || {},
    alias: indexFyrTVJqbx0Meta?.alias || [],
    redirect: indexFyrTVJqbx0Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/shop/[shop_id]/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexFyrTVJqbx0Meta?.name ?? "shop-shop_id-order___nl",
    path: indexFyrTVJqbx0Meta?.path ?? "/shop/:shop_id()/order",
    meta: indexFyrTVJqbx0Meta || {},
    alias: indexFyrTVJqbx0Meta?.alias || [],
    redirect: indexFyrTVJqbx0Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/shop/[shop_id]/order/index.vue").then(m => m.default || m)
  }
]